import * as React from "react"
import { graphql } from "gatsby"
import { Layout } from "../components/layout"
import HomeSlider from '../components/slider';
import ImgCard from '../components/ImgCard';
import './home-photo-grid.css';

import {
  intro,
  introContainer,
  featuredTitle,
  featuredText
} from "./index.module.css"



export default function IndexPage({ data: { products, homepagePhotos } }) {

  const photos = homepagePhotos.edges;
  let postCounter = 0;

  return (
    <Layout>

      <HomeSlider />

      <div className={introContainer}>
        <h1 className={intro}>Just Tom taking photos.</h1>
        <p>Tom Snaps started as just Tom taking photos and posting them on instagram. </p><br/>
        <p>Photography is a passion for Tom, going on adventures near and far snapping photos so others can see the world through his lens.</p>
      </div>

      <div className="image-grid home-grid">
          {photos.map(({ node }) => {
            postCounter++
            return (
              <ImgCard
                key={node.id}
                count={postCounter}
                node={node}
                postClass={`post`}
              />
            )
          })}
        </div>
    </Layout>
  )
}

export const query = graphql`
  {

    homepagePhotos: allFile(filter: {sourceInstanceName: {eq: "homepage-photos"}}, sort: {order: DESC, fields: name}) {
      edges {
          node {
              id
              publicURL
              modifiedTime
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
          }
        }
     }
  }
`