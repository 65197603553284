import React from 'react'
import { graphql, useStaticQuery } from "gatsby"
import BackgroundSlider from 'gatsby-image-background-slider'
import styled from 'styled-components'
// dont remove 
import { scriptFont, sliderWrapper, sliderOverlay, btn } from "./slider.module.css"

const HomeSlider = ({ children }) => {

const query = useStaticQuery(graphql`
  query {
    backgrounds: allFile (filter: {sourceInstanceName: {eq: "slider"}}){
      nodes {
        relativePath
        childImageSharp {
          fluid (maxWidth: 4000, quality: 100){
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  }
`)

  return (
    <div className={sliderWrapper}>
      <BackgroundSlider 
        query={query}
        initDelay={2} // delay before the first transition (if left at 0, the first image will be skipped initially)
        transition={4} // transition duration between images
        duration={8} // how long an image is shown
        // specify images to include (and their order) according to `relativePath`
        images={["brighton-collection.jpg", "beach-sunset.jpg", "chattri.jpg", "ditchling-beacon.jpg"]}          
      >
        {/* Captions in sync with background images*/}
        <div className={sliderOverlay}>Brighton Beach Huts</div>
        <div className={sliderOverlay}>Capturing the adventure of life</div>
        <div className={sliderOverlay}>Chattri</div>
        <div className={sliderOverlay}>Ditchling Beacon</div>
      </BackgroundSlider>
    </div>
  ) 
} 

export default HomeSlider